import React, { useEffect } from "react";
import "../../styles/posts.scss";

export const ModernisierteWebsite = () => {
  useEffect(() => {
    document.title = "Modernisierte Website | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);
  return (
    <div className="post-container">
      <title>Beitrag: Modernisierte Website</title>
      <meta
        name="description"
        content="Kurze Worte über das neue Webseiten Design."
      />
      <h1 className="title">Modernisierte Website</h1>
      <h2>
        Willkommen auf unserer modernisierten Website: Alles viel Einfacher!
      </h2>
      <p>
        Wir freuen uns, Ihnen unsere frisch überarbeitete Website präsentieren
        zu dürfen – ein Design, das nicht nur ansprechender ist, sondern auch
        deutlich einfacher zu bedienen ist.
      </p>
      <h3>Alles auf einem Blick:</h3>
      <p>
        Mit dem aktualisierten Design haben wir nicht nur auf Ästhetik gesetzt,
        sondern vor allem auf Funktionalität. Die Benutzerfreundlichkeit steht
        im Vordergrund, wodurch Sie nun schneller und einfacher finden, wonach
        Sie suchen. Unsere klare Struktur und übersichtliche Menüführung
        erleichtern Ihnen die Navigation, egal ob Sie über Ihren
        Desktop-Computer, Tablet oder Smartphone auf unsere Seite zugreifen.
      </p>
      <h3>Immer auf dem neuesten Stand:</h3>
      <p>
        Ihnen die aktuellsten Informationen zur Verfügung zu stellen, liegt uns
        am Herzen. Daher werden wir nun verstärkt darauf achten, unsere Website
        regelmäßig zu aktualisieren. Von Neuigkeiten über Änderungen bei den
        Parcours bis hin zu Zukünftigen Turnieren – Sie werden stets auf dem
        Laufenden gehalten
      </p>
      <h3>Ihre Meinung ist uns wichtig:</h3>
      <p>
        Ihre Erfahrung auf unserer Website liegt uns am Herzen. Wir freuen uns
        über Ihr Feedback, um kontinuierlich zu lernen und uns zu verbessern.
        Teilen Sie uns mit, wie Ihnen das neue Design gefällt und ob Sie weitere
        Anregungen haben, um Ihre Online-Erfahrung mit uns noch besser zu
        gestalten. Dazu können Sie gerne den „Feedback“ Button am Unteren Ende
        der Website verwenden.{" "}
        <p>Vielen Dank für die Unterstützung in den letzten Jahren.</p>
      </p>
      <h3>Herzlichst,</h3> <h3>Panorama Bogensport Neukirchen</h3>
    </div>
  );
};

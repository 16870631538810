import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SponsorPartnerForm } from "../components/forms/SponsorPartnerForm";

export const SponsorenPartner = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("sponsorenPartner.title");

    return () => {
      document.title = t("defaultTitle");
    };
  }, [t]);

  return (
    <div className="fullwidth">
      <title>{t("sponsorenPartner.pageTitle")}</title>
      <meta name="description" content={t("sponsorenPartner.description")} />
      <div className="flex-center">
        <h1 className="title">{t("sponsorenPartner.heading")}</h1>
        <p className="flex-banner-text">
          <br />
          {t("sponsorenPartner.greeting")}
          <br />
          <br />
          {t("sponsorenPartner.instructions")}
          <br />
          {t("sponsorenPartner.response")}
          <br />
          <br />
        </p>
        <div className="flex-form">
          <SponsorPartnerForm />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export const Banner = () => {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    window.scroll(0, 0);
  };

  return (
    <div className="full-width">
      <div
        className="flex banner"
        onClick={handleScrollToTop}
        aria-label={t("banner.title")}
      >
        <div className="flex-column flex-column-grey">
          <h1>{t("banner.title")}</h1>
          <p className="flex-banner-text">{t("banner.description")}</p>
          <Link to="/infos" onClick={handleScrollToTop}>
            <button className="bright-button">{t("banner.button")}</button>
          </Link>
        </div>
        <div className="banner-right">
          <LazyLoadImage
            threshold={100}
            src={require("../../img/main/banner-sommer.webp")}
            alt="banner-img"
            className="banner-img"
          />
        </div>
      </div>
    </div>
  );
};

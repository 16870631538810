import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/Navbar.scss";
import { TelContact } from "../components/general/TelContact";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <div className="footer">
        <TelContact />
        <div className="links">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("footer.home")}
          </NavLink>
          <NavLink
            to="/datenschutz"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("footer.privacy")}
          </NavLink>
          <NavLink
            to="/impressum"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("footer.imprint")}
          </NavLink>
        </div>
      </div>
      <div className="footer-mobile">
        <div className="footer-mobile-split">
          <div className="footer-contact nav-links-mobile-1 links-footer">
            <TelContact />
          </div>
        </div>
        <div className="footer-mobile-split">
          <div className="links links-footer">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${isActive ? "active-class" : "non-active-class"} nav-links`
              }
            >
              {t("footer.home")}
            </NavLink>
          </div>
          <div className="links links-footer">
            <NavLink
              to="/datenschutz"
              className={({ isActive }) =>
                `${isActive ? "active-class" : "non-active-class"} nav-links`
              }
            >
              {t("footer.privacy")}
            </NavLink>
          </div>
          <div className="links links-footer">
            <NavLink
              to="/impressum"
              className={({ isActive }) =>
                `${isActive ? "active-class" : "non-active-class"} nav-links`
              }
            >
              {t("footer.imprint")}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

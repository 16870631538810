import React from "react";
import { MainLogo } from "../components/home/MainLogo";
import { Banner } from "../components/home/Banner";
import { BrandShowcase } from "../components/home/BrandShowcase";
import { PartnerShowcase } from "../components/home/PartnerShowcase";
import { SponsorShowcase } from "../components/home/SponsorShowcase";
import { GoogleRate } from "../components/home/GoogleRate";
import { Beitraege } from "../components/home/Beitraege";

export const Home = () => {
  return (
    <div className="home">
      <title>Panorama Bogensport</title>
      <meta
        name="description"
        content="Einzigartige Bogenabenteuer + Einschulung & Leihausrüstung vom Weltmeister Christian Vorderegger"
      />
      <MainLogo />
      <div className="home-content">
        <GoogleRate />
        <SponsorShowcase />
        <PartnerShowcase />
        <BrandShowcase />
        <Beitraege />
        <Banner />
      </div>
    </div>
  );
};

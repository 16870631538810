// src/components/MainLogo.tsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MainLogo: React.FC = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [randomImage, setRandomImage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const mobileImages = [
      require("../../img/main/s24-shot1m.webp"),
      require("../../img/main/s24-shot2m.webp"),
      require("../../img/main/s24-wildschweinm.webp"),
      require("../../img/main/s24-steinm.webp"),
      require("../../img/main/s24-stallm.webp"),
      require("../../img/main/s24-spinne1m.webp"),
      require("../../img/main/s24-spinne2m.webp"),
      require("../../img/main/s24-spinne3m.webp"),
      require("../../img/main/s24-30m.webp"),
      require("../../img/main/s24-tier1m.webp"),
    ];

    const desktopImages = [
      require("../../img/main/s24-spinne2.webp"),
      require("../../img/main/s24-spinne1.webp"),
      require("../../img/main/s24-shot2.webp"),
      require("../../img/main/s24-shot1.webp"),
      require("../../img/main/s24-bock.webp"),
      require("../../img/main/s24-30.webp"),
      require("../../img/main/s24-stall.webp"),
      require("../../img/main/s24-wildschwein.webp"),
      require("../../img/main/s24-spinne3.webp"),
      require("../../img/main/s24-stein.webp"),
    ];

    let selectedImages = windowWidth < 768 ? mobileImages : desktopImages;
    const randomIndex = Math.floor(Math.random() * selectedImages.length);
    setRandomImage(selectedImages[randomIndex]);
  }, [windowWidth]);

  return (
    <div className="image-container">
      <div className="background">
        <img src={randomImage} alt="" className="background-image" />
      </div>
      <div className="foreground">
        <div className="gradient">
          <h1
            className="main-title"
            dangerouslySetInnerHTML={{ __html: t("main_title") }}
          ></h1>
          <p className="main-p">{t("main_subtitle")}</p>
          <Link
            to="/infos"
            className="main-button-container bright-button"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <div className="main-button">{t("button_text")}</div>
            <svg
              className="main-right"
              fill="#FFFFFF"
              height="30px"
              width="80px"
              viewBox="0 0 330 330"
            >
              <path
                id="XMLID_27_"
                d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255
                s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0
                c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export const ContactForm = () => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccess = () => {
    setIsSuccess(true);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    acceptRules: false,
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
    acceptRules: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: any = {};
    if (!formData.message.trim()) {
      errors.message = t("contactForm.errors.message");
    }
    if (!formData.acceptRules) {
      errors.acceptRules = t("contactForm.errors.acceptRules");
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const form = e.target as HTMLFormElement;

      const sendForm = async (form: HTMLFormElement) => {
        try {
          await emailjs.sendForm(
            "service_08usloe",
            "template_kvl107x",
            form,
            "cg0RWJuEFGhWmMajF"
          );
        } catch (error) {
          console.error(error);
        }
      };

      sendForm(form);
      showSuccess();
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h2 className={`sucess-message ${isSuccess ? "show" : ""}`}>
        {t("contactForm.successMessage")}
      </h2>
      <label htmlFor="name" className={formErrors.name ? "red-text" : ""}>
        {t("contactForm.labels.name")}
      </label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        className={formErrors.name ? "red-text" : ""}
        placeholder={t("contactForm.placeholders.name")}
      />

      <label htmlFor="email" className={formErrors.email ? "red-text" : ""}>
        {t("contactForm.labels.email")}
      </label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        className={formErrors.email ? "red-text" : ""}
        placeholder={t("contactForm.placeholders.email")}
      />

      <label htmlFor="message" className={formErrors.message ? "red-text" : ""}>
        {t("contactForm.labels.message")}
        <span className="red-text">*</span>
      </label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        className={formErrors.message ? "red-text" : ""}
        rows={10}
        placeholder={t("contactForm.placeholders.message")}
      />

      <div className="accept-rules">
        <input
          type="checkbox"
          id="acceptRules"
          name="acceptRules"
          checked={formData.acceptRules}
          onChange={handleCheckboxChange}
          className={`checkbox ${formErrors.acceptRules ? "red-text" : ""}`}
        />
        <label
          htmlFor="acceptRules"
          className={`rule-accept ${formErrors.acceptRules ? "red-text" : ""}`}
        >
          {t("contactForm.labels.acceptRules")}
          <span className="red-text">*</span>
        </label>
      </div>

      <button type="submit" className="form-button">
        {t("contactForm.submitButton")}
      </button>
    </form>
  );
};

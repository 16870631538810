// src/components/RequireAuth.tsx
import React from "react";
import { Navigate } from "react-router-dom";

interface RequireAuthProps {
  children: React.ReactNode;
  redirectTo: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, redirectTo }) => {
  const token = sessionStorage.getItem("token");
  return token ? <>{children}</> : <Navigate to={redirectTo} />;
};

export default RequireAuth;

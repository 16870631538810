import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PartnerCarousel } from "./PartnerCarousel";

export const PartnerShowcase = () => {
  const { t } = useTranslation();

  return (
    <div className="full-width partner-showcase">
      <div className="flex flex-radius">
        <div className="flex-column flex-column-grey">
          <h1>{t("partnerShowcase.title")}</h1>
          <p className="flex-banner-text">{t("partnerShowcase.description")}</p>
        </div>
        <div className="flex-column flex-column-grey flex-carousel">
          <PartnerCarousel />
        </div>
      </div>
    </div>
  );
};

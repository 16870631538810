import React from "react";
import ".././styles/admin-dashboard.scss";
import { NavLink } from "react-router-dom";

export const AdminDashboard = () => {
  return (
    <div className="admin-dashboard-container">
      <button>
        <NavLink to="/edit-notification">notification</NavLink>
      </button>
    </div>
  );
};

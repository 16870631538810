import React from "react";
import { useTranslation } from "react-i18next";
import { TelContact } from "../general/TelContact";

export const ParcoursInfos = () => {
  const { t } = useTranslation();

  return (
    <div className="flex parcours-infos-height">
      <div className="parcours-infos-left flex-column">
        <div className="flex-banner-text oeffnungszeiten">
          <h2 className="h2-parcours">{t("parcoursInfos.openingHours")}</h2>
          <table className="oeffnungszeiten-table">
            <thead>
              <tr>
                <th>{t("parcoursInfos.table.type")}</th>
                <th>{t("parcoursInfos.table.from")}</th>
                <th>{t("parcoursInfos.table.to")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("parcoursInfos.table.course")}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: t("parcoursInfos.table.hours.course.from"),
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: t("parcoursInfos.table.hours.course.to"),
                  }}
                />
              </tr>
              <tr>
                <td>{t("parcoursInfos.table.rental")}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: t("parcoursInfos.table.hours.rental.from"),
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: t("parcoursInfos.table.hours.rental.to"),
                  }}
                />
              </tr>
            </tbody>
          </table>
          <p className="flex-banner-text foot-note">
            {t("parcoursInfos.footNote")}
          </p>
        </div>
        <div className="verleih-voranmeldung flex-banner-text">
          <h2 className="h2-parcours-no-margin">
            {t("parcoursInfos.rentalPreRegistration")}
          </h2>
          <p
            dangerouslySetInnerHTML={{ __html: t("parcoursInfos.rentalText") }}
          />
          <div className="h2-parcours-no-margin">
            <TelContact />
          </div>
        </div>
        <div className="flex-banner-text">
          <h2 className="h2-parcours-no-margin">
            {t("parcoursInfos.terrain")}
          </h2>
          <p
            dangerouslySetInnerHTML={{ __html: t("parcoursInfos.terrainText") }}
          />
        </div>
      </div>
      <div className="parcours-infos-right flex-column">
        <div className="preise-sommer-2023">
          <h2 className="h2-parcours">{t("parcoursInfos.summerPrices2024")}</h2>
          <h4 className="preise-title h4-parcours">
            {t("parcoursInfos.beginner")}
          </h4>
          <table className="preise-table-1">
            <thead>
              <tr className="priese-table-heading">
                <th>{t("parcoursInfos.priceTable1.round")}</th>
                <th>{t("parcoursInfos.priceTable1.adult")}</th>
                <th>{t("parcoursInfos.priceTable1.child")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td-tall">
                  {t("parcoursInfos.priceTable1.panoramaRound")}
                </td>
                <td>30€</td>
                <td>25€</td>
              </tr>
              <tr>
                <td className="td-tall">
                  {t("parcoursInfos.priceTable1.meadowRound")}
                </td>
                <td>25€</td>
                <td>20€</td>
              </tr>
            </tbody>
          </table>
          <p className="flex-banner-text foot-note">
            {t("parcoursInfos.phoneRegistrationRequired")}
          </p>
          <h4 className="preise-title h4-parcours">
            {t("parcoursInfos.archersWithOwnEquipment")}
          </h4>
          <table className="preise-table-2">
            <thead>
              <tr>
                <th>{t("parcoursInfos.priceTable2.round")}</th>
                <th>{t("parcoursInfos.priceTable2.adult")}</th>
                <th>{t("parcoursInfos.priceTable2.child")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("parcoursInfos.priceTable2.panoramaRound")}</td>
                <td>15€</td>
                <td>10€</td>
              </tr>
            </tbody>
          </table>
          <p className="flex-banner-text foot-note">
            {t("parcoursInfos.cashOnly")}
          </p>
        </div>
        <div className="compound-regulierung">
          <h2 className="h2-parcours">
            {t("parcoursInfos.compoundRegulation")}
          </h2>
          <p
            className="flex-banner-text"
            dangerouslySetInnerHTML={{
              __html: t("parcoursInfos.compoundText"),
            }}
          />
        </div>
      </div>
    </div>
  );
};

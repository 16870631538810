import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/language-switcher.scss";

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const storedLanguage = window.localStorage.getItem("i18nextLng");
    if (storedLanguage && storedLanguage.startsWith("de")) {
      i18n.changeLanguage("de");
      setCurrentLanguage("de");
    } else {
      i18n.changeLanguage("en");
      setCurrentLanguage("en");
    }
  }, [i18n]);

  const languages = ["en", "de"];
  const currentIndex = languages.indexOf(currentLanguage);
  const nextLanguage = languages[(currentIndex + 1) % languages.length];

  const changeLanguage = () => {
    i18n.changeLanguage(nextLanguage);
    setCurrentLanguage(nextLanguage);
  };

  return (
    <div className="language-switcher">
      <button className="language-button" onClick={changeLanguage}>
        {currentLanguage === "en" ? "EN" : "DE"}
      </button>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SponsorCarousel } from "./SponsorCarousel";

export const SponsorShowcase = () => {
  const { t } = useTranslation();

  return (
    <div className="full-width sponsor-showcase">
      <div className="flex">
        <div className="flex-column flex-carousel">
          <SponsorCarousel />
        </div>
        <div className="flex-column">
          <h1>{t("sponsorShowcase.title")}</h1>
          <p className="flex-banner-text">{t("sponsorShowcase.description")}</p>
          <Link
            to="/sponsoren-partner"
            onClick={() => {
              window.scroll(0, 0);
            }}
          >
            <button className="partner-button">
              {t("sponsorShowcase.becomeSponsor")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../styles/Notification.scss";

interface NotificationData {
  title: string;
  message: string;
  visible: boolean;
  color: string;
}

export const Notification = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [notificationData, setNotificationData] = useState<NotificationData>({
    title: "",
    message: "",
    visible: false,
    color: "#c62828",
  });

  useEffect(() => {
    const handleScroll = () => {
      const notifyContainer = document.querySelector(
        ".notify-container"
      ) as HTMLElement;
      if (notifyContainer) {
        const originalTop = "7rem";
        const adjustedTop = "1rem";

        if (window.innerWidth > 768) {
          notifyContainer.style.top =
            window.pageYOffset > 100 ? adjustedTop : originalTop;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          "https://panroama-bogensport-api.onrender.com:3001/api/notification"
        );
        const data = response.data;
        setNotificationData(data);
        setIsVisible(data.visible);
      } catch (error) {
        console.error("Error fetching notification data:", error);
        setIsVisible(false);
      }
    };

    fetchNotification();
  }, []);

  if (!isVisible) return null;

  return (
    <div
      className="notify-container"
      style={{ backgroundColor: notificationData.color }}
    >
      {isVisible && (
        <div className="notify">
          <div className="notify-content">
            <h1>{notificationData.title}</h1>
            <p>{notificationData.message}</p>
          </div>
          <button onClick={() => setIsVisible(false)} className="close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );
};

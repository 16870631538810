import React, { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import "../styles/gallery.scss";

export const Galerie = () => {
  const { t } = useTranslation();

  // Import images
  const contextLowSommer2024 = (require as any).context(
    "../img/gallery/low/sommer2024",
    false,
    /\.(webp)$/
  );
  const urlsLowSommer2024: string[] = contextLowSommer2024
    .keys()
    .map(contextLowSommer2024);

  const contextLowWinter2024 = (require as any).context(
    "../img/gallery/low/winter2024",
    false,
    /\.(webp)$/
  );
  const urlsLowWinter2024: string[] = contextLowWinter2024
    .keys()
    .map(contextLowWinter2024);

  const contextLowSommer2023 = (require as any).context(
    "../img/gallery/low/sommer2023",
    false,
    /\.(webp)$/
  );
  const urlsLowSommer2023: string[] = contextLowSommer2023
    .keys()
    .map(contextLowSommer2023);

  const contextLowQuality = (require as any).context(
    "../img/gallery/low",
    false,
    /\.(webp)$/
  );
  const urlsLowQuality: string[] = contextLowQuality
    .keys()
    .map(contextLowQuality);

  const lowQualityImageUrls = [
    ...urlsLowSommer2024,
    ...urlsLowWinter2024,
    ...urlsLowSommer2023,
    ...urlsLowQuality,
  ];

  const contextHighSommer2024 = (require as any).context(
    "../img/gallery/high/sommer2024",
    false,
    /\.(webp)$/
  );
  const urlsHighSommer2024: string[] = contextHighSommer2024
    .keys()
    .map(contextHighSommer2024);

  const contextHighWinter2024 = (require as any).context(
    "../img/gallery/high/winter2024",
    false,
    /\.(webp)$/
  );
  const urlsHighWinter2024: string[] = contextHighWinter2024
    .keys()
    .map(contextHighWinter2024);

  const contextHighSommer2023 = (require as any).context(
    "../img/gallery/high/sommer2023",
    false,
    /\.(webp)$/
  );
  const urlsHighSommer2023: string[] = contextHighSommer2023
    .keys()
    .map(contextHighSommer2023);

  const imageContextHighQuality = (require as any).context(
    "../img/gallery/high",
    false,
    /\.(webp)$/
  );
  const urlsHighQuality: string[] = imageContextHighQuality
    .keys()
    .map(imageContextHighQuality);

  const highQualityImageUrls = [
    ...urlsHighSommer2024,
    ...urlsHighWinter2024,
    ...urlsHighSommer2023,
    ...urlsHighQuality,
  ];

  const [currentImage, setCurrentImage] = useState<number>(-1);
  const [visibleCount, setVisibleCount] = useState<number>(15);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && currentImage > 0) {
        setCurrentImage(currentImage - 1);
      } else if (
        event.key === "ArrowRight" &&
        currentImage < highQualityImageUrls.length - 1
      ) {
        setCurrentImage(currentImage + 1);
      } else if (event.key === "Escape") {
        setCurrentImage(-1);
      }
    },
    [currentImage, highQualityImageUrls.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const openImage = (index: number) => setCurrentImage(index);
  const closeImage = () => setCurrentImage(-1);

  const ModalDialog = ({
    image,
    lowImage,
    onNext,
    onPrev,
    onClose,
  }: {
    image: string;
    lowImage: string;
    onNext: () => void;
    onPrev: () => void;
    onClose: () => void;
  }) => (
    <div className="modal-dialog" onClick={onClose}>
      <span className="dot"></span>
      <svg
        className="gallery-close cursor-pointer"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <path
          d="M6 6L18 18M6 18L18 6"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
      </svg>

      <LazyLoadImage
        placeholderSrc={lowImage}
        effect="blur"
        src={image}
        alt=""
        style={{ maxWidth: "90vw", maxHeight: "90vh" }}
        onClick={(e) => e.stopPropagation()}
      />
      <svg
        className="gallery-arrow-left cursor-pointer"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        onClick={(e) => {
          e.stopPropagation();
          onPrev();
        }}
      >
        <path
          d="M11.5 11.5L18 18M11.5 12.5L18 6"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
      </svg>
      <svg
        className="gallery-arrow-right cursor-pointer"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        onClick={(e) => {
          e.stopPropagation();
          onNext();
        }}
      >
        <path
          d="M11.5 11.5L5 18M11.5 12.5L5 6"
          stroke="white"
          strokeWidth="2"
          fill="transparent"
        />
      </svg>
    </div>
  );

  return (
    <div className="gallery-container">
      <title>{t("gallery.title")}</title>
      <meta name="description" content={t("gallery.description")} />
      <h1 className="title">{t("gallery.title")}</h1>
      <div className="grid-container">
        {lowQualityImageUrls.slice(0, visibleCount).map((imageUrl, index) => (
          <LazyLoadImage
            key={index}
            placeholderSrc="blur"
            effect="blur"
            src={imageUrl}
            alt=""
            style={{
              width: "100%",
              display: "block",
              borderRadius: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => openImage(index)}
          />
        ))}
      </div>
      {lowQualityImageUrls.length > visibleCount && (
        <div
          className="load-more"
          onClick={() =>
            setVisibleCount((prevCount) =>
              Math.min(prevCount + 15, lowQualityImageUrls.length)
            )
          }
        >
          {t("gallery.loadMore")}
        </div>
      )}
      {currentImage >= 0 && (
        <ModalDialog
          image={highQualityImageUrls[currentImage]}
          lowImage={lowQualityImageUrls[currentImage]}
          onNext={() =>
            currentImage < highQualityImageUrls.length - 1 &&
            setCurrentImage(currentImage + 1)
          }
          onPrev={() => currentImage > 0 && setCurrentImage(currentImage - 1)}
          onClose={closeImage}
        />
      )}
    </div>
  );
};

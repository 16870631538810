// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend) // Load translations using http (from the public folder)
  .use(LanguageDetector) // Detect the user's language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    fallbackLng: "en", // Fallback language if the user's language is not available
    debug: true, // Enable console debugging (you can turn this off in production)
    interpolation: {
      escapeValue: false, // React already escapes values by default
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // Path to translation files
    },
  });

export default i18n;

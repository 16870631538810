import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TelContact } from "../components/general/TelContact";

export const Impressum = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("impressum.title");

    return () => {
      document.title = t("defaultTitle");
    };
  }, [t]);

  return (
    <div>
      <title>{t("impressum.pageTitle")}</title>
      <meta name="description" content={t("impressum.description")} />
      <h1 className="title">{t("impressum.heading")}</h1>
      <p>
        <br />
        {t("impressum.companyName")}
        <br />
        {t("impressum.location")}
        <br />
        <br />
        <TelContact />
        <br />
        {t("impressum.email")}
        <br />
        <br />
        {t("impressum.address")}
        <br />
        {t("impressum.zipCity")}
      </p>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const GoogleRate = () => {
  const { t } = useTranslation();

  return (
    <div className="full-width google-rate">
      <div className="flex flex-radius">
        <div className="flex-column flex-column-grey">
          <h1>{t("googleRate.title")}</h1>
          <p className="flex-banner-text">{t("googleRate.feedbackText")}</p>
          <p className="flex-banner-text">
            {t("googleRate.rating")} <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            <i className="star"></i>
            {t("googleRate.reviews")}
          </p>
          <Link
            to="https://www.google.com/maps/place/Panorama+Bogensport,+Sonnberg+53,+5741+Neukirchen+am+Gro%C3%9Fvenediger/@47.2594557,12.276062,17z/data=!4m6!3m5!1s0x4777bb12c7cd7e99:0x75247397498fe4c3!8m2!3d47.2594557!4d12.276062!16s%2Fg%2F11hdw8gj2b?authuser=0"
            target="_blank"
          >
            <button className="bright-button">{t("googleRate.rateNow")}</button>
          </Link>
        </div>
        <div className="flex-column">
          <Link
            to="https://www.google.com/maps/place/Panorama+Bogensport,+Sonnberg+53,+5741+Neukirchen+am+Gro%C3%9Fvenediger/@47.2594557,12.276062,17z/data=!4m6!3m5!1s0x4777bb12c7cd7e99:0x75247397498fe4c3!8m2!3d47.2594557!4d12.276062!16s%2Fg%2F11hdw8gj2b?authuser=0"
            target="_blank"
            className="map-link"
          >
            <img
              src={require("../../img/main/mapimagelow.webp")}
              alt="map-image"
              className="map-image img-zoom-in"
              loading="lazy"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContactForm } from "../components/forms/ContactForm";

export const Feedback = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("feedback.title");

    return () => {
      document.title = t("defaultTitle");
    };
  }, [t]);

  return (
    <div className="fullwidth">
      <title>{t("feedback.pageTitle")}</title>
      <meta name="description" content={t("feedback.description")} />
      <div className="flex-center">
        <h1 className="title">{t("feedback.heading")}</h1>
        <p className="flex-banner-text">{t("feedback.text")}</p>
        <div className="flex-form">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

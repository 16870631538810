import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Navbar } from "./navbar/Navbar";
import "./styles/App.scss";
import { Home } from "./pages/Home";
import { Infos } from "./pages/Infos";
import { Galerie } from "./pages/Galerie";
import { Footer } from "./Footer/Footer";
import { SponsorenPartner } from "./pages/SponsorenPartner";
import { Feedback } from "./pages/Feedback";
import { Impressum } from "./pages/Impressum";
import { ParcoursSommer2023 } from "./pages/posts/ParcoursSommer2023";
import "./styles/custom-scrollbar.scss";
import { Datenschutz } from "./pages/Datenschutz";
import ScrollToTop from "./components/general/ScrollToTop";
import { ParcoursWinter2024 } from "./pages/posts/ParcoursWinter2024";
import { ModernisierteWebsite } from "./pages/posts/ModernisierteWebsite";
import { BilderAlt } from "./pages/posts/BilderAlt";
import { AuftaktSchiassn } from "./pages/posts/AuftaktSchiassn";
import { SpiderbowChallenge1 } from "./pages/posts/SpiderbowChallenge1";
import { ParcoursSommer2024 } from "./pages/posts/ParcoursSommer2024";
import { Notification } from "./notification/Notification";
import RequireAuth from "./components/RequireAuth"; // Import RequireAuth
import Login from "./components/Login"; // Import Login
import Register from "./components/Register"; // Import Register
import EditNotification from "./pages/EditNotification"; // Import EditNotification
import { AdminDashboard } from "./pages/AdminDashboard";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import "./i18n";

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <SpeedInsights />
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <Notification />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/infos" element={<Infos />} />
            <Route path="/galerie" element={<Galerie />} />
            <Route path="/sponsoren-partner" element={<SponsorenPartner />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/post/bilder-alt" element={<BilderAlt />} />
            <Route
              path="/post/auftakt-schiassn"
              element={<AuftaktSchiassn />}
            />
            <Route
              path="/post/Spiderbow-challenge-1"
              element={<SpiderbowChallenge1 />}
            />
            <Route
              path="/post/modernisierte-website"
              element={<ModernisierteWebsite />}
            />
            <Route
              path="/post/parcours-sommer-2023"
              element={<ParcoursSommer2023 />}
            />
            <Route
              path="/post/parcours-winter-2024"
              element={<ParcoursWinter2024 />}
            />
            <Route
              path="/post/parcours-sommer-2024"
              element={<ParcoursSommer2024 />}
            />
            <Route
              path="/parcour-location"
              element={<Navigate to="/infos" />}
            />
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/login" element={<Login />} /> {/* Add Login Route */}
            <Route path="/register" element={<Register />} />{" "}
            {/* Add Register Route */}
            {/* Protected routes */}
            <Route
              path="/admin"
              element={
                <RequireAuth redirectTo="/login">
                  <AdminDashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-notification"
              element={
                <RequireAuth redirectTo="/login">
                  <EditNotification />
                </RequireAuth>
              }
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

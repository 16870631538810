import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles/datenschutz.scss";

export const Datenschutz = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("datenschutz.title");

    return () => {
      document.title = t("defaultTitle");
    };
  }, [t]);

  return (
    <div>
      <title>{t("datenschutz.pageTitle")}</title>
      <meta name="description" content={t("datenschutz.description")} />
      <h1 className="title">{t("datenschutz.heading")}</h1>
      <div className="flex datenschutz-text">
        <p
          dangerouslySetInnerHTML={{ __html: t("datenschutz.content") }}
          style={{ width: "100%" }}
        ></p>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParcoursInfos } from "../components/Parcours/ParcoursInfos";

export const Infos = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("infos.title");

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, [t]);

  return (
    <div>
      <title>{t("infos.title")}</title>
      <meta name="description" content={t("infos.description")} />
      <h1 className="title">{t("infos.header")}</h1>
      <ParcoursInfos />
      <div className="flex">
        <h1>{t("infos.roundCardTitle")}</h1>
      </div>
      <div className="flex">
        <img
          src={require("../img/panorama-karte-sommer-2024.webp")}
          alt={t("infos.mapAlt")}
          className="runden-karte"
          loading="lazy"
        />
      </div>
    </div>
  );
};

import React from "react";
import "../../styles/post-thumbnails.scss";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

export const Beitraege = () => {
  const { t } = useTranslation();
  const lazyLoadThreshold = 100;
  return (
    <div className="beitraege-div">
      <h1>{t("beitraege.title")}</h1>
      <div className="posts-container">
        <Link className="post" to={"/post/parcours-sommer-2024"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/parcours-sommer-2024.webp")}
            alt="parcours-sommer-2024"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="post-icon"
          >
            <path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" />
          </svg>
          <h2>{t("beitraege.parcours_sommer_2024.title")}</h2>
          <p>{t("beitraege.parcours_sommer_2024.date")}</p>
        </Link>

        <Link className="post" to={"/post/parcours-winter-2024"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/parcours-winter-2024.webp")}
            alt="parcours-winter-2024"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="post-icon"
          >
            <path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" />
          </svg>
          <h2>{t("beitraege.parcours_winter_2024.title")}</h2>
          <p>{t("beitraege.parcours_winter_2024.date")}</p>
        </Link>

        <Link className="post" to={"/post/parcours-sommer-2023"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/parcours-sommer-2023.webp")}
            alt="parcours-sommer-2023"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="post-icon"
          >
            <path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" />
          </svg>
          <h2>{t("beitraege.parcours_sommer_2023.title")}</h2>
          <p>{t("beitraege.parcours_sommer_2023.date")}</p>
        </Link>

        <Link className="post" to={"/post/modernisierte-website"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/modernisierte-website.webp")}
            alt="modernisierte-website"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            className="post-icon"
          >
            <path d="M192 32h64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384l0 352c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-352H288V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H192c-88.4 0-160-71.6-160-160s71.6-160 160-160z" />
          </svg>
          <h2>{t("beitraege.modernisierte_website.title")}</h2>
          <p>{t("beitraege.modernisierte_website.date")}</p>
        </Link>

        <Link className="post" to={"/post/spiderbow-challenge-1"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/1.-spiderbows-challenge.webp")}
            alt="1.-spiderbows-challenge"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className="post-icon"
          >
            <path d="M256 0H576c35.3 0 64 28.7 64 64V288c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64zM476 106.7C471.5 100 464 96 456 96s-15.5 4-20 10.7l-56 84L362.7 169c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H552c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144zM336 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 128h96V384v32c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V384H512v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64zm8 64c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V312c0-8.8-7.2-16-16-16H72zm0 104c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16H88c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H72zm336 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16V416c0-8.8-7.2-16-16-16H424c-8.8 0-16 7.2-16 16z" />
          </svg>
          <h2>{t("beitraege.spiderbow_challenge_1.title")}</h2>
          <p>{t("beitraege.spiderbow_challenge_1.date")}</p>
        </Link>

        <Link className="post" to={"/post/auftakt-schiassn"}>
          <LazyLoadImage
            threshold={lazyLoadThreshold}
            src={require("../../img/posts/bilder-auftakt-schiassn.webp")}
            alt="bilder-auftakt-schiassn"
            className="post-img"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            className="post-icon"
          >
            <path d="M160 32c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160zM396 138.7l96 144c4.9 7.4 5.4 16.8 1.2 24.6S480.9 320 472 320H328 280 200c-9.2 0-17.6-5.3-21.6-13.6s-2.9-18.2 2.9-25.4l64-80c4.6-5.7 11.4-9 18.7-9s14.2 3.3 18.7 9l17.3 21.6 56-84C360.5 132 368 128 376 128s15.5 4 20 10.7zM192 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" />
          </svg>
          <h2>{t("beitraege.auftakt_schiassn.title")}</h2>
          <p>{t("beitraege.auftakt_schiassn.date")}</p>
        </Link>
      </div>
    </div>
  );
};

import "../../styles/posts.scss";
import GalleryComponent from "../../components/images/GalleryComponent";
import { useEffect } from "react";

export const ParcoursSommer2023 = () => {
  const lowContext = (require as any).context(
    "../../img/gallery/low/sommer2023",
    false,
    /\.(webp)$/
  );
  const highContext = (require as any).context(
    "../../img/gallery/high/sommer2023",
    false,
    /\.(webp)$/
  );

  useEffect(() => {
    document.title = "Sommer 2023 | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);

  return (
    <div>
      <title>Beitrag: Parcours Sommer 2023</title>
      <meta name="description" content="Alle Bilder vom Parcours Sommer 2023" />
      <h1 className="title">Parcours Sommer 2023</h1>
      <div className="gallery-container">
        <GalleryComponent lowContext={lowContext} highContext={highContext} />
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../styles/gallery.scss";

interface RequireContext {
  keys(): string[];
  <T>(id: string): T;
  <T>(id: string, recursive: boolean): T;
  <T>(id: string, recursive: boolean, regExp: RegExp): T;
}

interface GalleryProps {
  highContext: RequireContext;
  lowContext: RequireContext;
}

const ModalDialog: React.FC<{
  image: string;
  lowImage: string;
  onNext: () => void;
  onPrev: () => void;
  onClose: () => void;
}> = ({ image, lowImage, onNext, onPrev, onClose }) => (
  <div className="modal-dialog" onClick={onClose}>
    <span className="dot"></span>
    <svg
      className="gallery-close cursor-pointer"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
    >
      <path
        d="M6 6L18 18M6 18L18 6"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
    <LazyLoadImage
      placeholderSrc={lowImage}
      effect="blur"
      src={image}
      alt=""
      style={{ maxWidth: "90vw", maxHeight: "90vh" }}
      onClick={(e) => e.stopPropagation()}
    />
    <svg
      className="gallery-arrow-left cursor-pointer"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      onClick={(e) => {
        e.stopPropagation();
        onPrev();
      }}
    >
      <path
        d="M11.5 11.5L18 18M11.5 12.5L18 6"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
    <svg
      className="gallery-arrow-right cursor-pointer"
      width="50"
      height="50"
      viewBox="0 0 24 24"
      onClick={(e) => {
        e.stopPropagation();
        onNext();
      }}
    >
      <path
        d="M11.5 11.5L5 18M11.5 12.5L5 6"
        stroke="white"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
  </div>
);

const GalleryComponent: React.FC<GalleryProps> = ({
  highContext,
  lowContext,
}) => {
  const lowUrls: string[] = lowContext.keys().map((key) => lowContext(key));
  const highUrls: string[] = highContext.keys().map((key) => highContext(key));

  const [currentImage, setCurrentImage] = useState<number>(-1);
  const [visibleCount, setVisibleCount] = useState<number>(15); // Adjust the number of initially visible images

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && currentImage > 0) {
        setCurrentImage(currentImage - 1);
      } else if (
        event.key === "ArrowRight" &&
        currentImage < highUrls.length - 1
      ) {
        setCurrentImage(currentImage + 1);
      } else if (event.key === "Escape") {
        setCurrentImage(-1);
      }
    },
    [currentImage, highUrls.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  const openImage = (index: number) => setCurrentImage(index);
  const closeImage = () => setCurrentImage(-1);

  return (
    <div className="gallery-container">
      <div className="grid-container">
        {lowUrls.slice(0, visibleCount).map((imageUrl, index) => (
          <LazyLoadImage
            key={index}
            placeholderSrc="blur"
            effect="blur"
            src={imageUrl}
            alt=""
            style={{
              width: "100%",
              display: "block",
              borderRadius: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => openImage(index)}
          />
        ))}
        {lowUrls.length > visibleCount && (
          <div
            className="load-more"
            onClick={() =>
              setVisibleCount((prevCount) =>
                Math.min(prevCount + 15, lowUrls.length)
              )
            }
          >
            Mehr Laden
          </div>
        )}
      </div>
      {currentImage >= 0 && (
        <ModalDialog
          image={highUrls[currentImage]}
          lowImage={lowUrls[currentImage]}
          onNext={() =>
            currentImage < highUrls.length - 1 &&
            setCurrentImage(currentImage + 1)
          }
          onPrev={() => currentImage > 0 && setCurrentImage(currentImage - 1)}
          onClose={closeImage}
        />
      )}
    </div>
  );
};

export default GalleryComponent;

import "../../styles/posts.scss";
import GalleryComponent from "../../components/images/GalleryComponent";
import { useEffect } from "react";

export const BilderAlt = () => {
  const lowContext = (require as any).context(
    "../../img/gallery/low",
    false,
    /\.(webp)$/
  );
  const highContext = (require as any).context(
    "../../img/gallery/high",
    false,
    /\.(webp)$/
  );

  useEffect(() => {
    document.title = "Bilder alt | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);

  return (
    <div>
      <title>Beitrag: Bilder alt</title>
      <meta name="description" content="Die alten Bilder bis 2020" />
      <h1 className="title">Bilder alt</h1>
      <div className="gallery-container">
        <GalleryComponent lowContext={lowContext} highContext={highContext} />
      </div>
    </div>
  );
};

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const SponsorCarousel = () => {
  const lazyThreshold = 100;
  return (
    <div className="logo-carousel">
      <div className="logo-carousel-slide-sponsor">
        <LazyLoadImage
          threshold={lazyThreshold}
          src={require("../../img/logos/sponsors/sponsoren-min.webp")}
          alt="sponsoren"
        />
        <LazyLoadImage
          threshold={lazyThreshold}
          src={require("../../img/logos/sponsors/sponsoren-min.webp")}
          alt="sponsoren"
        />
      </div>
      <div className="logo-carousel-slide-sponsor">
        <LazyLoadImage
          threshold={lazyThreshold}
          src={require("../../img/logos/sponsors/sponsoren-min.webp")}
          alt="sponsoren"
        />
        <LazyLoadImage
          threshold={lazyThreshold}
          src={require("../../img/logos/sponsors/sponsoren-min.webp")}
          alt="sponsoren"
        />
      </div>
    </div>
  );
};

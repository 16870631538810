import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "../styles/Navbar.scss";
import { TelContact } from "../components/general/TelContact";
import { SocialLinks } from "../components/general/SocialLinks";
import { LanguageSwitcher } from "../components/LanguageSwitcher";
import { useTranslation } from "react-i18next";

export const Navbar = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [show, setShow] = useState(true);
  const [scrollPos, setScrollPos] = useState(0);
  const [hasBackground, setHasBackground] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos < 100) {
      setHasBackground(false);
    } else {
      setHasBackground(true);
    }

    if (currentScrollPos > scrollPos) {
      setShow(false);
    } else {
      setShow(true);
    }

    setScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPos]);

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;

    if (isMenuOpen) {
      body.style.overflowY = "hidden";
      html.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "initial";
      html.style.overflowY = "initial";
    }

    return () => {
      body.style.overflowY = "initial";
      html.style.overflowY = "initial";
    };
  }, [isMenuOpen]);

  return (
    <div
      className={`navbar-container ${
        hasBackground ? "showbackground" : "hidebackground"
      } ${show ? "active" : "hidden"}`}
    >
      <nav className="navbar">
        <Link to="/" className="logo">
          <img
            src={require("../img/logos/Logo-small.webp")}
            alt="Panorama-Bogensport"
            className="logo"
          />
        </Link>
        <div className="links">
          <LanguageSwitcher />
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("navbar.home")}
          </NavLink>
          <NavLink
            to="infos"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("navbar.info")}
          </NavLink>
          <NavLink
            to="/galerie"
            className={({ isActive }) =>
              `${isActive ? "active-class" : "non-active-class"} nav-links`
            }
          >
            {t("navbar.gallery")}
          </NavLink>

          <div className="more nav-links">
            {t("navbar.more")}{" "}
            <svg className="chevron-down-svg" viewBox="0 0 512 512">
              <path
                className="chevron-down-stroke"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="48"
                d="M112 184l144 144 144-144"
              />
            </svg>
            <div
              className={`dropdown ${
                hasBackground ? "showbackground" : "hidebackground"
              }`}
            >
              <NavLink
                to="/sponsoren-partner"
                className={({ isActive }) =>
                  `${
                    isActive ? "active-class" : "non-active-class"
                  } dropdown-link dropdown-link-1 nav-links`
                }
              >
                {t("navbar.sponsor_partner")}
              </NavLink>
              <a
                href="flyer-2024.pdf"
                download
                className="dropdown-link nav-links dropdown-bottom"
              >
                {t("navbar.download_folder")}
              </a>
              <NavLink
                to="/feedback"
                className={({ isActive }) =>
                  `${
                    isActive ? "active-class" : "non-active-class"
                  } dropdown-link nav-links`
                }
              >
                {t("navbar.feedback")}
              </NavLink>
              <NavLink
                to="/impressum"
                className={({ isActive }) =>
                  `${
                    isActive ? "active-class" : "non-active-class"
                  } dropdown-link nav-links dropdown-bottom`
                }
              >
                {t("navbar.impressum")}
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
      <nav className="navbar-mobile">
        <Link to="/" className="logo">
          <img
            src={require("../img/logos/Logo-small.webp")}
            alt="Panorama-Bogensport"
            className="logo"
          />
        </Link>
        <div className="links">
          <LanguageSwitcher />
          <svg
            className={`hamburger ${isMenuOpen ? "hidden" : ""}`}
            width="50"
            height="50"
            viewBox="0 0 24 24"
            onClick={handleMenuToggle}
          >
            <path
              className="hamburger-hover"
              d="M12 18H3V16H12V18ZM21 13H3V11H21V13ZM21 8H12V6H21V8Z"
              fill="white"
            />
          </svg>
          <svg
            className={`cross ${!isMenuOpen ? "hidden" : ""}`}
            width="50"
            height="50"
            viewBox="0 0 24 24"
            onClick={handleMenuToggle}
          >
            <path
              className="cross-hover"
              d="M6 6L18 18M6 18L18 6"
              stroke="white"
              strokeWidth="2"
              fill="transparent"
            />
          </svg>

          <div
            className={`dropdown ${!isMenuOpen ? "hidden" : ""}`}
            onClick={handleMenuToggle}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links dropdown-margin dropdown-first`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.home")}
            </NavLink>
            <NavLink
              to="infos"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.info")}
            </NavLink>
            <NavLink
              to="/galerie"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.gallery")}
            </NavLink>
            <NavLink
              to="/sponsoren-partner"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.sponsor_partner")}
            </NavLink>
            <NavLink
              to="/feedback"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.feedback")}
            </NavLink>
            <NavLink
              to="/impressum"
              className={({ isActive }) =>
                `${
                  isActive ? "active-class" : "non-active-class"
                } dropdown-link nav-links dropdown-bottom`
              }
              onClick={handleMenuToggle}
            >
              {t("mobile-navbar.impressum")}
            </NavLink>
            <a
              href="flyer-2024.pdf"
              download
              className="dropdown-link nav-links dropdown-bottom"
            >
              {t("mobile-navbar.download_folder")}
            </a>

            <div className="mobile-menu-social">
              <SocialLinks />
            </div>
            <div className="mobile-menu-contact">
              <TelContact />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const BrandShowcase = () => {
  const { t } = useTranslation();

  return (
    <div className="full-width brand-showcase">
      <div className="flex">
        <div className="flex-column">
          <a
            href="https://spiderbows.com/i/c-v-edition"
            className="img-zoom logo-animated-2"
            target="_blank"
            rel="noreferrer"
            aria-label="spiderbows.com"
          >
            <LazyLoadImage
              threshold={100}
              src={require("../../img/logos/cvboegen-removebg-preview.webp")}
              alt="cv-edition-img"
              className="logo-animated-2"
            />
          </a>
          {/* Uncomment and update these links if needed
          <a href="https://spiderbows.com" className="img-zoom" target="_blank" rel="noreferrer">
            <img src={require("../img/logos/spiderbows.webp")} alt="spiderbows-img" className="logo-animated" />
          </a>
          <a href="https://falkenholz.de" className="img-zoom" target="_blank" rel="noreferrer">
            <img src={require("../img/logos/falkenholz.webp")} alt="falkenholz-img" className="logo-animated" />
          </a>
          */}
        </div>
        <div className="flex-column">
          <h1>{t("brandShowcase.title")}</h1>
          <p
            className="flex-banner-text"
            dangerouslySetInnerHTML={{ __html: t("brandShowcase.description") }}
          />
          <Link to="https://spiderbows.com/i/c-v-edition/" target="_blank">
            <button className="bright-button">
              {t("brandShowcase.button")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

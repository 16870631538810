import React from "react";

export const TelContact = () => {
  return (
    <div className="contact">
      Christian Vorderegger
      <br />
      Tel.:{" "}
      <a href="tel:+43 660 8688811" className="phone-link">
        +43 660 8688811
      </a>
    </div>
  );
};

import React, { useEffect } from "react";
import "../../styles/posts.scss";

export const SpiderbowChallenge1 = () => {
  useEffect(() => {
    document.title = "1. Spiderbow Challenge | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);
  return (
    <div className="post-container">
      <title>Beitrag: 1. Spiderbow Challenge 2020</title>
      <meta
        name="description"
        content="Videos und ein Paar Worte von unserer ersten Spiderbow Challenge 2020"
      />
      <h1 className="title">1. Spiderbow Challenge 2020</h1>
      <video controls width="100%">
        <source src={require("../../vid/teil-1.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <video controls width="100%">
        <source src={require("../../vid/teil-2.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h3>Wir sagen Danke</h3>

      <p>
        Wir bedanken uns nochmals recht herzlich bei all den Schützen für das
        zahlreiche kommen und die unser gestriges Turnier wieder zu einen
        unvergesslichen Tag gemacht haben. 🌻🎉🏹{" "}
        <p>
          Ein großes Dankeschön geht auch an alle Sponsoren die uns mit so
          vielen und mega Preisen eine so tolle Verlosung ermöglichten.
        </p>
      </p>

      <p>
        Wir bedanken uns auch bei unseren Familien die uns so toll unterstützt
        und mitgeholfen haben ohne Euch wäre das nicht möglich Danke sagen wir
        auch der Familie Kaserer Alpengasthof Stockenbaum ohne Euch wäre das
        alles erst gar nicht möglich. Ein Danke gilt auch eurem fleißigen Team
        ihr seid spitze.
      </p>

      <p>Wir bedanken uns</p>
      <p>Christian und Sandra</p>
      <p>und hoffen auf ein baldiges Wiedersehen</p>
    </div>
  );
};

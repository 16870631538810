import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/auth.scss"; // Ensure this path is correct

const EditNotification: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(true);
  const [color, setColor] = useState<string>("#ffffff"); // New state for color
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          "https://panroama-bogensport-api.onrender.com::3001/api/notification"
        );
        const data = response.data;
        setTitle(data.title);
        setMessage(data.message);
        setVisible(data.visible);
        setColor(data.color); // Set color from response data
      } catch (error) {
        console.error("Error fetching notification:", error);
      }
    };

    fetchNotification();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      await axios.put(
        "https://panroama-bogensport-api.onrender.com:3001/api/notification",
        {
          title,
          message,
          visible,
          color, // Include color in the request
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Handle success response, e.g., navigate or show a success message
    } catch (error) {
      console.error("Error updating notification:", error);
      // Handle error response, e.g., show an error message
    }
  };

  return (
    <form onSubmit={handleSubmit} className="edit-notification">
      <div className="form-group">
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Message</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Color</label>
        <input
          type="color"
          value={color}
          onChange={(e) => setColor(e.target.value)}
          required
          className="form-control"
        />
      </div>
      <div className="row">
        <div className="form-group">
          <label>Visible</label>
          <label className="switch">
            <input
              type="checkbox"
              checked={visible}
              onChange={(e) => setVisible(e.target.checked)}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </form>
  );
};

export default EditNotification;

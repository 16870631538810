import React, { useEffect, useState } from "react";

export const PartnerCarousel = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="logo-carousel-div">
      <div className="logo-carousel">
        <div className="logo-carousel-slide">
          {windowWidth < 768 ? (
            <div>
              z
              <a href="https://www.hotel-gassner.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-gassner-min-min.webp")} alt="hotel-gassner" />
              </a>
              <a href="https://www.wanderhotel.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-kirchner-min-min.webp")} alt="hotel-kirchner" />
              </a>
              <a href="https://www.wildkogelresorts.at/waldvogel/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/waldvogel-min-min.webp")} alt="waldvogel" />
              </a>
              <a href="https://www.wildkogelresorts.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/wildkogel-resorts-min-min.webp")} alt="widlkogel-resorts" />
              </a>
              <a href="https://www.rechtegg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/alpengasthof-rechtegg-min-min.webp")}
                  alt="alpengasthof-rechtegg"
                />
              </a>
              <a href="https://www.stockenbaum.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/stockenbaum-min-min.webp")} alt="stockenbaum" />
              </a>
              <a href="https://www.huettendorf-salzburg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/naturdorf-oberkuehnreit-min-min.webp")}
                  alt="naturdorf-oberhuehnreit"
                />
              </a>
            </div>
          ) : (
            <div>
              <a href="https://www.hotel-gassner.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-gassner-min.webp")} alt="hotel-gassner" />
              </a>
              <a href="https://www.wanderhotel.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-kirchner-min.webp")} alt="hotel-kirchner" />
              </a>
              <a href="https://www.wildkogelresorts.at/waldvogel/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/waldvogel-min.webp")} alt="waldvogel" />
              </a>
              <a href="https://www.wildkogelresorts.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/wildkogel-resorts-min.webp")} alt="widlkogel-resorts" />
              </a>
              <a href="https://www.rechtegg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/alpengasthof-rechtegg-min.webp")}
                  alt="alpengasthof-rechtegg"
                />
              </a>
              <a href="https://www.stockenbaum.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/stockenbaum-min.webp")} alt="stockenbaum" />
              </a>
              <a href="https://www.huettendorf-salzburg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/naturdorf-oberkuehnreit-min.webp")}
                  alt="naturdorf-oberhuehnreit"
                />
              </a>
            </div>
          )}
        </div>
        <div className="logo-carousel-slide">
          {windowWidth < 768 ? (
            <div>
              <a href="https://www.hotel-gassner.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-gassner-min-min.webp")} alt="hotel-gassner" />
              </a>
              <a href="https://www.wanderhotel.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-kirchner-min-min.webp")} alt="hotel-kirchner" />
              </a>
              <a href="https://www.wildkogelresorts.at/waldvogel/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/waldvogel-min-min.webp")} alt="waldvogel" />
              </a>
              <a href="https://www.wildkogelresorts.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/wildkogel-resorts-min-min.webp")} alt="widlkogel-resorts" />
              </a>
              <a href="https://www.rechtegg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/alpengasthof-rechtegg-min-min.webp")}
                  alt="alpengasthof-rechtegg"
                />
              </a>
              <a href="https://www.stockenbaum.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/stockenbaum-min-min.webp")} alt="stockenbaum" />
              </a>
              <a href="https://www.huettendorf-salzburg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/naturdorf-oberkuehnreit-min-min.webp")}
                  alt="naturdorf-oberhuehnreit"
                />
              </a>
            </div>
          ) : (
            <div>
              <a href="https://www.hotel-gassner.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-gassner-min.webp")} alt="hotel-gassner" />
              </a>
              <a href="https://www.wanderhotel.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/hotel-kirchner-min.webp")} alt="hotel-kirchner" />
              </a>
              <a href="https://www.wildkogelresorts.at/waldvogel/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/waldvogel-min.webp")} alt="waldvogel" />
              </a>
              <a href="https://www.wildkogelresorts.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/wildkogel-resorts-min.webp")} alt="widlkogel-resorts" />
              </a>
              <a href="https://www.rechtegg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/alpengasthof-rechtegg-min.webp")}
                  alt="alpengasthof-rechtegg"
                />
              </a>
              <a href="https://www.stockenbaum.at/" target="_blank" rel="noreferrer">
                <img src={require("../../img/logos/partners/stockenbaum-min.webp")} alt="stockenbaum" />
              </a>
              <a href="https://www.huettendorf-salzburg.com/" target="_blank" rel="noreferrer">
                <img
                  src={require("../../img/logos/partners/naturdorf-oberkuehnreit-min.webp")}
                  alt="naturdorf-oberhuehnreit"
                />
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import "../../styles/posts.scss";
import GalleryComponent from "../../components/images/GalleryComponent";
import { useEffect } from "react";

export const ParcoursSommer2024 = () => {
  const lowContext = (require as any).context(
    "../../img/gallery/low/sommer2024",
    false,
    /\.(webp)$/
  );
  const highContext = (require as any).context(
    "../../img/gallery/high/sommer2024",
    false,
    /\.(webp)$/
  );

  useEffect(() => {
    document.title = "Sommer 2024 | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);

  return (
    <div>
      <title>Beitrag: Parcours Sommer 2024</title>
      <meta name="description" content="Alle Bilder vom Parcours Sommer 2024" />
      <h1 className="title">Parcours Sommer 2024</h1>
      <div className="gallery-container">
        <GalleryComponent lowContext={lowContext} highContext={highContext} />
      </div>
    </div>
  );
};

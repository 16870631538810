import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

export const SponsorPartnerForm = () => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccess = () => {
    setIsSuccess(true);
  };

  const [formData, setFormData] = useState({
    select: "",
    name: "",
    email: "",
    tel: "",
    message: "",
    acceptRules: false,
  });

  const [formErrors, setFormErrors] = useState({
    select: "",
    name: "",
    email: "",
    tel: "",
    message: "",
    acceptRules: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });

    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors: any = {};
    if (!formData.name.trim()) {
      errors.name = t("sponsorPartnerForm.companyLabel") + " " + t("required");
    }
    if (!formData.email.trim()) {
      errors.email = t("sponsorPartnerForm.emailLabel") + " " + t("required");
    }
    if (!formData.message.trim()) {
      errors.message =
        t("sponsorPartnerForm.messageLabel") + " " + t("required");
    }
    if (!formData.acceptRules) {
      errors.acceptRules =
        t("sponsorPartnerForm.acceptRulesLabel") + " " + t("required");
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const form = e.target as HTMLFormElement;

      const sendForm = async (form: HTMLFormElement) => {
        try {
          await emailjs.sendForm(
            "service_08usloe",
            "template_m08qpnt",
            form,
            "cg0RWJuEFGhWmMajF"
          );
        } catch (error) {
          console.error(error);
        }
      };

      sendForm(form);
      showSuccess();
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h2 className={`sucess-message ${isSuccess ? "show" : ""}`}>
        {t("sponsorPartnerForm.successMessage")}
      </h2>
      <label htmlFor="select" className={formErrors.select ? "red-text" : ""}>
        {t("sponsorPartnerForm.selectLabel")}
        <span className="red-text">{t("sponsorPartnerForm.required")}</span>
      </label>
      <select
        id="select"
        name="select"
        value={formData.select}
        onChange={handleSelectChange}
      >
        <option value="sponsor">Sponsor</option>
        <option value="partner">Partner</option>
      </select>

      <label htmlFor="name" className={formErrors.name ? "red-text" : ""}>
        {t("sponsorPartnerForm.companyLabel")}
        <span className="red-text">{t("sponsorPartnerForm.required")}</span>
      </label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        className={formErrors.name ? "red-text" : ""}
        placeholder={t("sponsorPartnerForm.companyLabel")}
      />

      <label htmlFor="email" className={formErrors.email ? "red-text" : ""}>
        {t("sponsorPartnerForm.emailLabel")}
        <span className="red-text">{t("sponsorPartnerForm.required")}</span>
      </label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        className={formErrors.email ? "red-text" : ""}
        placeholder={t("sponsorPartnerForm.emailLabel")}
      />

      <label htmlFor="tel" className={formErrors.tel ? "red-text" : ""}>
        {t("sponsorPartnerForm.telLabel")}
      </label>
      <input
        type="tel"
        id="tel"
        name="tel"
        value={formData.tel}
        onChange={handleInputChange}
        className={formErrors.tel ? "red-text" : ""}
        placeholder={t("sponsorPartnerForm.telLabel")}
      />

      <label htmlFor="message" className={formErrors.message ? "red-text" : ""}>
        {t("sponsorPartnerForm.messageLabel")}
        <span className="red-text">{t("sponsorPartnerForm.required")}</span>
      </label>
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleInputChange}
        className={formErrors.message ? "red-text" : ""}
        rows={10}
        placeholder={t("sponsorPartnerForm.messageLabel")}
      />

      <div className="accept-rules">
        <input
          type="checkbox"
          id="acceptRules"
          name="acceptRules"
          checked={formData.acceptRules}
          onChange={handleCheckboxChange}
          className={`checkbox ${formErrors.acceptRules ? "red-text" : ""}`}
        />
        <label
          htmlFor="acceptRules"
          className={`rule-accept ${formErrors.acceptRules ? "red-text" : ""}`}
        >
          {t("sponsorPartnerForm.acceptRulesLabel")}
          <span className="red-text">{t("sponsorPartnerForm.required")}</span>
        </label>
      </div>
      <button type="submit" className="form-button">
        {t("sponsorPartnerForm.sendButton")}
      </button>
    </form>
  );
};

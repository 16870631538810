import "../../styles/posts.scss";
import GalleryComponent from "../../components/images/GalleryComponent";
import { useEffect } from "react";

export const AuftaktSchiassn = () => {
  const lowContext = (require as any).context(
    "../../img/posts/auftakt-schiassn/low",
    false,
    /\.(webp)$/
  );
  const highContext = (require as any).context(
    "../../img/posts/auftakt-schiassn/high",
    false,
    /\.(webp)$/
  );

  useEffect(() => {
    document.title = "Auftakt Schiassn | Panorama Bogensport";

    return () => {
      document.title = "Panorama Bogensport";
    };
  }, []);

  return (
    <div>
      <title>Beitrag: Auftakt Schiassn</title>
      <meta name="description" content="Bilder vom Auftakt Schiassn 2019" />
      <h1 className="title">Auftakt Schiassn</h1>
      <div className="gallery-container">
        <GalleryComponent lowContext={lowContext} highContext={highContext} />
      </div>
    </div>
  );
};
